import { useState } from 'react';
import Slider from 'react-slick';

import { Button } from 'components';

const ProductCard = (props) => {
  const { title, subtitle, images, description, right, onDownload } = props;
  const [active, setActive] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    beforeChange: (prev, next) => {
      setActive(next);
    },
    appendDots: (dots) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {dots.map((dot, idx) => (
          <div key={idx}>{dot}</div>
        ))}
      </div>
    ),
    customPaging: (i) => {
      const isCurrent = i === active;

      return (
        <div
          style={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            background: isCurrent ? '#2A9D8F' : '#b6b6b6',
          }}
        />
      );
    },
  };

  return (
    <div
      className={`flex flex-col md:flex-row ${
        right ? 'md:flex-row-reverse' : 'md:flex-row'
      } md:space-x-4 md:justify-between md:items-center`}
    >
      <figure className="w-full md:w-6/12 h-full mb-8">
        <Slider {...sliderSettings}>
          {images.map((img, idx) => (
            <div key={idx}>
              <img src={img} alt={`product-${idx + 1}`} />
            </div>
          ))}
        </Slider>
      </figure>
      <article className={`py-4 w-full md:w-5/12 h-full`}>
        <div className="mb-6">
          <h2 className="text-subtitle md:text-title2">{title}</h2>
          <span className="text-normal md:text-content italic text-mainGray-subtitle font-light">
            {subtitle}
          </span>
        </div>
        <p className="text-sm md:text-normal leading-normal text-black">
          {description}
        </p>
        {onDownload && (
          <div className="mt-8">
            <Button type="secondary" onClick={onDownload}>
              Download Catalogue
            </Button>
          </div>
        )}
      </article>
    </div>
  );
};

export default ProductCard;
