import {
  Hero,
  Header,
  Migrating,
  Footer,
  CustomDevelopment,
  Products,
} from 'components';

import useWindowSize from 'utils/window-size';

export default function Home() {
  const { width } = useWindowSize();

  const isMobile = width <= 768;

  return (
    <>
      <main>
        <Header />
        <Hero />
        <Migrating />
        <Products />
        <CustomDevelopment isMobile={isMobile} />
        <Footer />
      </main>

      <button
        className="bg-mainGreen hover:bg-mainGray-background hover:text-mainGreen h-10 w-10 md:h-12 md:w-12 fixed bottom-4 right-4 md:bottom-8 md:right-8 cursor-pointer text-white font-bold text-xl md:text-2xl rounded-lg shadow-md active:outline-none focus:outline-none"
        onClick={() => {
          window.scrollTo(0, 0);
          window.location.href = '#';
        }}
      >
        &uarr;
      </button>
    </>
  );
}
