import Fade from 'react-reveal/Fade';

import { ProductCard } from 'components';

import SPPImage1 from 'assets/images/products/spp-1.png';
import SPPImage2 from 'assets/images/products/spp-2.png';
import SimpegImage1 from 'assets/images/products/simpeg-1.png';
import SimpegImage2 from 'assets/images/products/simpeg-2.png';
import KabarAnakImage1 from 'assets/images/products/kabaranak-1.png';
import KabarAnakImage2 from 'assets/images/products/kabaranak-2.png';
import ERPImage1 from 'assets/images/products/erp-1.png';
import ERPImage2 from 'assets/images/products/erp-2.png';

const productList = [
  {
    title: 'Sistem Tagihan Sekolah',
    subtitle: '*Ready to use & available for customization',
    images: [SPPImage1, SPPImage2],
    description: `This system is designed to help educational institution to
      manage and monitor all student bills and debts— to improve the
      effectiveness and transparency of institution financial
      management.`,
    onDownload: () =>
      window.open(
        'https://storage.googleapis.com/storage-accelego/Sistem%20Informasi%20Tagihan%20Sekolah.pdf',
        '_blank'
      ),
  },
  {
    title: 'Sistem Kepegawaian',
    subtitle: '*Ready to use & available for custom',
    images: [SimpegImage1, SimpegImage2],
    description: `This system facilitates a better and effective ways to deal with employee information, positions, and salaries. `,
    onDownload: () =>
      window.open(
        'https://storage.googleapis.com/storage-accelego/Sistem%20Kepegawaian.pdf',
        '_blank'
      ),
  },
  {
    title: 'ERP System',
    subtitle: '*Available for customization',
    images: [ERPImage1, ERPImage2],
    description: `This system is created for a medium sized smart meter company in Indonesia. The goal of this project is to integrate the new system between various divisions 
      including marketing, PPIC, procurement, after sales, quality control, warehouse, production & vendor portal. 
      The outcome: there is clear transparency within the
      company for every form requested.`,
    onDownload: null,
  },
  {
    title: 'KabarAnak Mobile-App',
    subtitle: '',
    images: [KabarAnakImage1, KabarAnakImage2],
    description: `A pre-school digital platform that bridges the communication gap between teachers and parents in 
      monitoring daily activities and children's mental and academic development.`,
    onDownload: null,
  },
];

const Products = () => {
  return (
    <section
      id="products"
      className="px-8 md:px-24 py-2 my-8 md:my-16 space-y-4 md:space-y-8"
    >
      <h2 className="font-bold md:text-center">Our Products</h2>

      <section className="space-y-8">
        {productList.map((product, index) => {
          const isOdd = index % 2 !== 0;

          return (
            <Fade right={isOdd} left={!isOdd} key={index}>
              <ProductCard {...product} right={isOdd} />
            </Fade>
          );
        })}
      </section>
    </section>
  );
};

export default Products;
