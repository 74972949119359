import { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';

import { NavLink } from 'components';
import { BurgerMenu } from 'assets';

import LogoDark from 'assets/images/accelego-logo-dark.svg';

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];

    body.onscroll = () => {
      const header = document.getElementById('main-header');
      if (window.scrollY > 568) {
        header.classList.remove('bg-mainGray-background');
        header.classList.add('bg-white');
        header.classList.add('shadow-md');
      } else {
        header.classList.add('bg-mainGray-background');
        header.classList.remove('bg-white');
        header.classList.remove('shadow-md');
      }
    };
  }, []);

  return (
    <header
      id="main-header"
      className="bg-mainGray-background grid grid-cols-10 px-6 md:px-24 h-16 fixed top-0 left-0 w-full z-10"
    >
      <div className="col-span-5 flex items-center w-40 h-full">
        <img src={LogoDark} alt="logo-header" className="image__logo" />
      </div>
      <nav className="flex col-span-5 items-center justify-end">
        <div className="hidden md:flex h-full">
          <NavLink text="Our Products" to="#products" />
          <NavLink text="Custom Products" to="#custom" />
          <NavLink text="Contact" to="#contact" />
        </div>

        <button
          className="block md:hidden cursor-pointer focus:outline-none active:outline-none"
          onClick={() => setOpenMenu(true)}
        >
          <BurgerMenu />
        </button>
      </nav>

      <Fade left when={openMenu}>
        <nav
          id="mobile-openMenu"
          className={`${
            openMenu ? 'flex flex-col' : 'hidden'
          } p-8 md:hidden absolute top-0 left-0 h-screen w-screen bg-white`}
        >
          <header className="flex justify-end">
            <h2 className="m-0" onClick={() => setOpenMenu(false)}>
              x
            </h2>
          </header>
          <div className="space-y-4">
            <h3
              className="cursor-pointer"
              onClick={() => {
                window.location.href = '#products';
                setOpenMenu(false);
              }}
            >
              Our Products
            </h3>
            <h3
              className="cursor-pointer"
              onClick={() => {
                window.location.href = '#custom';
                setOpenMenu(false);
              }}
            >
              Custom Products
            </h3>
            <h3
              className="cursor-pointer"
              onClick={() => {
                window.location.href = '#contact';
                setOpenMenu(false);
              }}
            >
              Contact
            </h3>
          </div>
        </nav>
      </Fade>
    </header>
  );
};

export default Header;
