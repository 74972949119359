import LogoLight from 'assets/images/accelego-logo-light.svg';
import IconWA from 'assets/images/contact-wa.svg';
import IconTelegram from 'assets/images/contact-telegram.svg';

const Footer = () => {
  return (
    <footer
      id='contact'
      className='bg-black-dark w-full h-full space-y-10 md:space-y-20 px-8 md:px-24 py-6 md:py-8'
    >
      <div className='flex flex-col md:flex-row-reverse justify-between'>
        <div className='flex space-x-4 md:space-x-8'>
          <a href='https://wa.me/6282111161253?text=Hi+Accelego,+Saya+ingin+bertanya.' target='_blank'>
            <div>
              <img src={IconWA} className='image__contact' alt='Image-wa' />
            </div>
          </a>
          {/* <div>
            <img
              src={IconTelegram}
              className='image__contact'
              alt='Image-telegram'
            />
          </div> */}
        </div>

        <div className='text-white text-normal md:text-content my-4 space-y-3'>
          <h4 className='font-bold'>PT. Accelego Kreasi Digital</h4>
          <a className='underline' href='mailto:hi@accelego.id'>
            hi@accelego.id
          </a>
          <p className='w-full' style={{ fontWeight: '300' }}>
            Gedung Wirausaha Lt. 1 Unit 104 <br />
            Jl. HR Rasuna Said Kav. C-5 <br />
            Jakarta Selatan, 12920
          </p>
        </div>
      </div>

      <div className='space-y-3 md:flex md:items-center md:justify-between'>
        <img src={LogoLight} alt='logo-footer' className='image__logo' />
        <p className='text-black-light2 text-xs md:text-sm'>
          &copy; {new Date().getFullYear()} Accelego All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
