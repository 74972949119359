import { useEffect, useState } from 'react';

const Button = (props) => {
  const { children, type, onClick, ...rest } = props;

  const [classes, setClasses] = useState(
    'rounded-xl focus:outline-none active:outline-none text-normal md:text-content py-2 px-8 font-regular'
  );

  useEffect(() => {
    if (type) {
      let newClass = classes;
      if (type === 'primary') {
        newClass += ` bg-mainGreen text-white hover:bg-mainGreen-darker`;
      } else {
        newClass += ` bg-white text-mainGreen border hover:bg-mainGreen hover:text-white`;
      }
      setClasses(newClass);
    }
  }, [type]);

  return (
    <button className={classes} onClick={onClick} {...rest}>
      {children}
    </button>
  );
};

export default Button;
