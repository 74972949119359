import TextLoop from 'react-text-loop';

import { Button } from 'components';

import HeroImage from 'assets/images/hero-image.png';

const Hero = () => {
  return (
    <section className="bg-mainGray-background px-6 md:px-24 py-2 grid grid-cols-1 md:grid-cols-2">
      <article className="flex flex-col justify-center items-start space-y-4 md:space-y-6 mt-16">
        <div>
          <h1>Software House</h1>
          <h2 className="font-extralight flex items-center gap-3 text-2xl md:text-4xl">
            &#10230;{' '}
            <TextLoop>
              <span>ERP System</span>
              <span>Sistem Tagihan</span>
              <span>Sistem Kepegawaian</span>
              <span>Mobile Apps</span>
            </TextLoop>
          </h2>
        </div>

        <p className="w-full md:w-3/4 text-normal md:text-subtitle font-light">
          Hi! We’re software house based in Indonesia aiming to accelerate your
          business through digital technology.
        </p>

        <Button
          type="primary"
          onClick={() => (window.location.href = '#products')}
        >
          View Products
        </Button>
      </article>
      <figure className="flex items-center justify-end w-full h-full mt-8 md:mt-16">
        <img src={HeroImage} className="image__hero" alt="image-hero" />
      </figure>
    </section>
  );
};

export default Hero;
