import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';

import IconWeb from 'assets/images/Web.svg';
import IconMobile from 'assets/images/Mobile.svg';
import IconSoftware from 'assets/images/Software.svg';
import DevelopmentImage from 'assets/images/custom-development.png';

const CustomServices = [
  {
    title: 'Web App Development',
    description:
      'We can build custom web applications from end to end, for any device. We are using the most popular and advance web application stacks such as React JS, Vue, NodeJS, MongoDB.',
    icon: IconWeb,
    color: 'bg-purple-light',
  },
  {
    title: 'Mobile App Development',
    description:
      'As a mobile app development company we know that apps for Android and iOS must be functional and look beautiful. Our mobile developers use modern technology and clean design to create exceptional experiences for all mobile devices.',
    icon: IconMobile,
    color: 'bg-mainGray',
  },
  {
    title: 'Software Development',
    description:
      'Our best developers, designers, and product owners are ready to add their skills and knowledge to your project. We’ve worked across many industries, including Manufacturing, Schools Institution, Education, and Distributors.',
    icon: IconSoftware,
    color: 'bg-mainBlue-light',
  },
];

const CustomDevelopment = ({ isMobile }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
    autoplay: isMobile ? true : false,
    autoplaySpeed: 3000,
    centerMode: isMobile ? true : false,
    adaptiveHeight: true,
    centerPadding: '10px',
  };

  return (
    <section
      id="custom"
      className="px-0 py-2 md:py-4 flex flex-col space-y-6 my-8"
    >
      <div className="px-8 md:px-24">
        <Fade top>
          <div className="bg-mainGreen-lighter w-full h-auto md:h-auto p-8 md:p-16 space-y-10 rounded-3xl grid grid-cols-1 md:grid-cols-3">
            <div className="space-y-2 md:col-span-2">
              <h2 className="font-bold">Need a custom system development?</h2>
              <p
                className="text-subtitle cursor-pointer"
                onClick={() => (window.location.href = '#contact')}
              >
                &#10230;&nbsp; Contact Us
              </p>
            </div>

            <figure className="md:col-span-1">
              <img
                src={DevelopmentImage}
                alt="image-development"
                className="image__development"
              />
            </figure>
          </div>
        </Fade>
      </div>

      <div className="max-w-screen px-6 md:px-20">
        <Fade bottom>
          {/* <div className="px-4 md:px-20"> */}
          <Slider {...sliderSettings}>
            {CustomServices.map((service, index) => {
              return (
                <div className="px-1 md:px-4">
                  <div
                    className={`${service.color} w-auto h-full p-8 space-y-2 rounded-3xl`}
                  >
                    <figure>
                      <img
                        src={service.icon}
                        alt="icon-services"
                        width={90}
                        height={90}
                        layout="intrinsic"
                      />
                    </figure>
                    <div className="space-y-1.5">
                      <h3 className="text-subtitle font-semibold">
                        {service.title}
                      </h3>
                      <p className="text-sm h-48 md:h-40 leading-normal">
                        {service.description}
                      </p>
                      <p
                        className="text-md cursor-pointer"
                        onClick={() => (window.location.href = '#contact')}
                      >
                        &#10230;&nbsp; Contact Us
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          {/* </div> */}
        </Fade>
      </div>
    </section>
  );
};

export default CustomDevelopment;
