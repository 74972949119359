import MigrationImage from 'assets/images/migrating.png';

const Migrating = () => {
  return (
    <section className="px-8 py-2 my-8 md:my-16 flex flex-col items-center">
      <div className="text-left md:flex md:flex-col md:items-center md:text-center space-y-3 w-full md:w-4/6">
        <h2 className="font-bold">
          Migrating your manual process to a digital workflow
        </h2>
        <p className="font-light text-normal md:text-content w-full md:w-3/4">
          If your business struggles to efficiently accomplish tasks and goals,
          we are here to help — Collecting and organizing business document is
          no longer a hassle when it’s been digitized.
        </p>
      </div>

      <figure className="mt-12 md:mt-24 flex justify-center">
        <img
          src={MigrationImage}
          className="image__migration"
          alt="image-migration"
        />
      </figure>
    </section>
  );
};

export default Migrating;
