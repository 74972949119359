const NavLink = ({ text, to }) => {
  return (
    <a
      className="hover:text-mainGreen text-lg h-full flex items-center text-center px-8"
      href={to}
    >
      {text}
    </a>
  );
};

export default NavLink;
